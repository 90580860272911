@import (reference) 'core/vars';
@import (reference) 'core/colors';
@import (reference) 'core/mixins';
@import (reference) 'core/header';

@import 'modules/navigation/meta-menu/meta-menu-styles-desktop';
@import 'modules/navigation/push-menu/push-menu-desktop';
@import 'shared/fonticons';
@import 'modules/navigation/breadcrumbs/breadcrumbs-desktop';
@import 'modules/navigation/breadcrumbs/deepcrumbs';
@import 'modules/navigation/fixed-header/fixed-header-desktop';
@import 'modules/navigation/footer/footer-desktop';
@import 'modules/navigation/language-switch/language-switch-desktop';
@import 'modules/navigation/sub-menu/sub-menu-desktop';
@import 'modules/navigation/search/search-desktop';
@import 'modules/navigation/search/search-results';
@import 'modules/navigation/service-menu/service-menu-desktop';
@import 'modules/navigation/push-menu/push-menu-styles-desktop';

// Always uncollapse filter
.filter.sub-menu {
	[data-accordion-content] {
		height: auto !important;
	}
}

.visible-lt-ie9 {
	display: block;
}
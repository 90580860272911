.meta-menu {
  margin-left: 7px;
  font-size: 15px;
}
.meta-menu--toggler {
  background: #E8E8E8;
  transition: background 200ms;
}
.meta-menu--toggler:hover {
  background: #dbdbdb;
}
.meta-menu--toggler span {
  text-transform: uppercase;
  font-family: 'openSans-Bold-webfont', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  margin-left: 17px;
}
.meta-menu--toggler .gfx {
  position: absolute;
  font-size: 0.7em;
}
.meta-menu--content {
  background: rgba(244, 244, 244, 0.95);
  z-index: 1;
}
.no-rgba .meta-menu--content {
  background: #F4F4F4;
}
.meta-menu--content:after {
  content: '';
  position: absolute;
  background: #E8E8E8;
  top: 0;
  right: 0;
  bottom: 0;
  left: 80%;
}
@media (min-width:1920px) {
  .meta-menu--content:after {
    left: 68%;
  }
}
.meta-menu--content > .container {
  position: relative;
  z-index: 1;
}
.flexbox .meta-menu--content > nav > ul,
.flexboxlegacy .meta-menu--content > nav > ul {
  display: flex;
  justify-content: space-between;
}
.no-flexbox.no-flexboxlegacy .meta-menu--content > nav > ul:before,
.no-flexbox.no-flexboxlegacy .meta-menu--content > nav > ul:after {
  content: " ";
  display: table;
}
.no-flexbox.no-flexboxlegacy .meta-menu--content > nav > ul:after {
  clear: both;
}
.no-flexbox.no-flexboxlegacy .meta-menu--content > nav > ul > li {
  float: left;
  width: 25%;
}
.meta-menu--content .first-level {
  padding: 1em;
}
@media (min-width:992px) {
  .meta-menu--content .first-level {
    padding: 2em;
  }
}
.meta-menu--content .first-level > a {
  font-family: 'openSans-Semibold-webfont', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 17px;
  text-decoration: none;
  margin-bottom: 0.5em;
}
.meta-menu--content .first-level > nav {
  height: auto !important;
}
.meta-menu--content .first-level.meta-menu--contact {
  background: #E8E8E8;
  overflow: hidden;
  margin-bottom: -99em;
  padding-bottom: 100em;
}
.meta-menu--content .first-level.meta-menu--contact nav a {
  display: block;
  position: relative;
  padding-left: 1.5em;
  font-family: 'openSans-Semibold-webfont', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
}
.meta-menu--content .first-level.meta-menu--contact nav a:before {
  position: absolute;
  left: 0;
}
.meta-menu--content .first-level.meta-menu--contact nav li {
  margin-top: 0.5em;
}
.meta-menu--content .first-level.meta-menu--contact nav li:first-child {
  margin-top: 0;
}
#header {
  position: fixed;
  width: 100%;
  top: 32px;
}
#header:after {
  content: '';
  height: 2px;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  background: #0C2447;
}
.anthracite #header:after {
  background: #343434;
}
.marine #header:after {
  background: #0C2447;
}
.orange #header:after {
  background: #FCC200;
}
.green #header:after {
  background: #008C50;
}
.red #header:after {
  background: #C7063E;
}
.purple #header:after {
  background: #B71570;
}
.sky #header:after {
  background: #0085C6;
}
.ami #header:after {
  background: #fab500;
}
.agef #header:after {
  background: #702283;
}
.baurecht #header:after {
  background: #fddb04;
}
.aref #header:after {
  background: #d31920;
}
.fmentoring #header:after {
  background: #B1CB31;
}
.regard #header:after {
  background: #DC861C;
}
.push-menu {
  z-index: 1;
  position: relative;
}
.push-menu li {
  position: relative;
}
.push-menu .level:not(.default) {
  position: absolute;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.95) translate(0, 10%);
  transition: transform 300ms, opacity 300ms;
}
.lt-ie9 .push-menu .level {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}
.lt-ie9 .push-menu .level.default {
  position: relative;
  visibility: visible;
  opacity: 1;
}
.push-menu .hover > .level {
  opacity: 1;
  transform: scale(1) translate(0, 0);
}
.push-menu .visible > .level {
  visibility: visible;
}
.push-menu > .level > ul > li > .level {
  top: 100%;
}
.push-menu > .level > ul > li > .level > ul > li > .level {
  top: 0;
  left: 100%;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
@font-face {
  font-family: "fonticons";
  src: url('//cdn.unifr.ch/uf/v2.4.0/fonts/fonticons.eot');
  src: url('//cdn.unifr.ch/uf/v2.4.0/fonts/fonticons.eot?#iefix') format('eot'), url('//cdn.unifr.ch/uf/v2.4.0/fonts/fonticons.woff') format('woff'), url('//cdn.unifr.ch/uf/v2.4.0/fonts/fonticons.ttf') format('truetype'), url('//cdn.unifr.ch/uf/v2.4.0/fonts/fonticons.svg#fonticons') format('svg');
  font-weight: normal;
  font-style: normal;
}
.gfx:before,
.gfx.after:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: "fonticons";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  vertical-align: top;
  line-height: inherit;
}
.gfx.after:before {
  display: none;
}
.gfx-arrow-right:before {
  content: "\E001";
}
.gfx-arrow-up:before {
  content: "\E002";
}
.gfx-arrow-left:before {
  content: "\E003";
}
.gfx-arrow-down:before {
  content: "\E004";
}
.gfx-close:before {
  content: "\E005";
}
.gfx-directions:before {
  content: "\E006";
}
.gfx-facebook:before {
  content: "\E007";
}
.gfx-home:before {
  content: "\E008";
}
.gfx-lock:before {
  content: "\E009";
}
.gfx-logo:before {
  content: "\E00A";
}
.gfx-mail:before {
  content: "\E00B";
}
.gfx-pin:before {
  content: "\E00C";
}
.gfx-tiles:before {
  content: "\E00D";
}
.gfx-triangle-thin-down:before {
  content: "\E00E";
}
.gfx-triangle-thin-left:before {
  content: "\E00F";
}
.gfx-triangle-thin-right:before {
  content: "\E010";
}
.gfx-triangle-thin-up:before {
  content: "\E011";
}
.gfx-twitter:before {
  content: "\E012";
}
.gfx-user:before {
  content: "\E013";
}
.gfx-search:before {
  content: "\E014";
}
.gfx-triangle-right:before {
  content: "\E015";
}
.gfx-triangle-up:before {
  content: "\E016";
}
.gfx-triangle-left:before {
  content: "\E017";
}
.gfx-triangle-down:before {
  content: "\E018";
}
.gfx-hamburger:before {
  content: "\E019";
}
.gfx-external:before {
  content: "\E01A";
}
.gfx-download:before {
  content: "\E01B";
}
.gfx-upload:before {
  content: "\E01C";
}
.gfx-file:before {
  content: "\E01D";
}
.gfx-check:before {
  content: "\E01E";
}
.gfx-dot:before {
  content: "\E01F";
}
.gfx-google-plus:before {
  content: "\E020";
}
.gfx-linkedin:before {
  content: "\E021";
}
.after.gfx-arrow-right:after {
  content: "\E001";
}
.after.gfx-arrow-up:after {
  content: "\E002";
}
.after.gfx-arrow-left:after {
  content: "\E003";
}
.after.gfx-arrow-down:after {
  content: "\E004";
}
.after.gfx-close:after {
  content: "\E005";
}
.after.gfx-directions:after {
  content: "\E006";
}
.after.gfx-facebook:after {
  content: "\E007";
}
.after.gfx-home:after {
  content: "\E008";
}
.after.gfx-lock:after {
  content: "\E009";
}
.after.gfx-logo:after {
  content: "\E00A";
}
.after.gfx-mail:after {
  content: "\E00B";
}
.after.gfx-pin:after {
  content: "\E00C";
}
.after.gfx-tiles:after {
  content: "\E00D";
}
.after.gfx-triangle-thin-down:after {
  content: "\E00E";
}
.after.gfx-triangle-thin-left:after {
  content: "\E00F";
}
.after.gfx-triangle-thin-right:after {
  content: "\E010";
}
.after.gfx-triangle-thin-up:after {
  content: "\E011";
}
.after.gfx-twitter:after {
  content: "\E012";
}
.after.gfx-user:after {
  content: "\E013";
}
.after.gfx-search:after {
  content: "\E014";
}
.after.gfx-triangle-right:after {
  content: "\E015";
}
.after.gfx-triangle-up:after {
  content: "\E016";
}
.after.gfx-triangle-left:after {
  content: "\E017";
}
.after.gfx-triangle-down:after {
  content: "\E018";
}
.after.gfx-hamburger:after {
  content: "\E019";
}
.after.gfx-external:after {
  content: "\E01A";
}
.after.gfx-download:after {
  content: "\E01B";
}
.after.gfx-upload:after {
  content: "\E01C";
}
.after.gfx-file:after {
  content: "\E01D";
}
.after.gfx-check:after {
  content: "\E01E";
}
.after.gfx-dot:after {
  content: "\E01F";
}
.after.gfx-google-plus:after {
  content: "\E020";
}
.after.gfx-linkedin:after {
  content: "\E021";
}
.breadcrumbs {
  overflow: hidden;
  padding-left: 0;
}
.breadcrumbs--content {
  height: auto !important;
}
.breadcrumbs--content ul {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}
.breadcrumbs--content li {
  display: inline;
  float: none;
}
.breadcrumbs--content li.gfx:before {
  padding: 1px 0.5em 0;
  font-size: 0.8em;
}
.breadcrumbs--mobile .long {
  display: none;
}
.breadcrumbs--mobile .desktop-expanded .short {
  display: none;
}
.breadcrumbs--mobile .desktop-expanded .long {
  display: inline;
  font-family: 'openSans-Bold-webfont', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
}
.breadcrumbs--mobile .desktop-expanded ~ li {
  display: none;
}
.deepcrumbs {
  padding: 20px 0;
  color: #AA9D8F;
  font-size: 13px;
}
.deepcrumbs ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}
.deepcrumbs ul:before,
.deepcrumbs ul:after {
  content: " ";
  display: table;
}
.deepcrumbs ul:after {
  clear: both;
}
.deepcrumbs ul > li {
  float: left;
}
.deepcrumbs li {
  padding-left: 0.5em;
}
.deepcrumbs li:before {
  content: '·';
  padding-right: 0.5em;
}
.deepcrumbs li:first-child {
  padding-left: 0;
}
.deepcrumbs li:first-child:before {
  content: none;
}
.deepcrumbs li:last-child {
  font-family: 'openSans-Bold-webfont', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
}
.deepcrumbs a {
  display: inline-block;
  transition: color 300ms;
}
.deepcrumbs a:hover {
  color: #343434;
}
.deepcrumbs a.bold {
  margin-top: 1px;
  margin-bottom: -1px;
}
@media (max-width:767px) {
  .deepcrumbs {
    display: none;
  }
}
#wrapper .fixed-header {
  font-size: 13px;
  /* .lt-ie9 & {
		background: @grey-light;
	} */
}
#wrapper .fixed-header .fixed-header--bg {
  background: #F4F4F4;
}
#footer > .container {
  padding-top: 2em;
  padding-bottom: 2em;
}
#footer > .container > .row {
  overflow: hidden;
}
#footer .footer-menu h6 {
  margin-top: 2.5em;
}
#footer .footer-menu h6:first-child {
  margin-top: 0;
}
@media (max-width:991px) {
  #footer .footer-menu {
    display: none;
  }
}
@media (min-width:992px) {
  #footer .footer-menu--contact {
    border-left: 1px solid white;
    padding-bottom: 2000px;
    margin-bottom: -2000px;
  }
}
#footer .footer-menu--contact ul {
  margin-top: 2.5em;
}
@media (max-width:991px) {
  #footer .footer-menu--contact ul {
    margin-top: 0;
  }
}
#footer .footer-menu--contact li {
  margin-top: 0.5em;
}
@media (max-width:991px) {
  #footer .footer-menu--contact li {
    margin-top: 1em;
  }
}
#footer .footer-menu--meta .footer--social-links {
  float: right;
}
@media (min-width:992px) {
  #footer .footer-menu--meta .footer--social-links {
    width: 25%;
  }
}
.fixed-header .language-switch {
  float: right;
}
.fixed-header .language-switch ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}
.fixed-header .language-switch ul:before,
.fixed-header .language-switch ul:after {
  content: " ";
  display: table;
}
.fixed-header .language-switch ul:after {
  clear: both;
}
.fixed-header .language-switch ul > li {
  float: left;
}
.fixed-header .language-switch li {
  border-left: 1px solid #343434;
}
.fixed-header .language-switch li:first-child {
  border-left: none;
}
.fixed-header .language-switch a {
  display: block;
  padding: 0 0.5em;
}
.sub-menu {
  /*> nav {
		.inner(0.5em);
		margin-left:-30px;
		> h4{
			padding-left: 30px;
		}


		//not happy with this structure. Would like to revisit
		& > div > ul > li {
			& > a{
				margin-left: 30px;				
			}

			& > div > ul > li{
				& > a {
					margin-left: 30px;						
					padding-left: 30px;
				}

				& > div > ul > li{
					margin-left: 0px;						
					padding-left: 30px;
					
					& a{
						padding-left: 60px;
						
					}
					&.active{
						& a{
							padding-left: 57px;
						}
					
					}
				}
			}

			&.active{
				& > a{
					margin-left: 27px;
				}				
			}
			
		}
		& li.active{
			border-left: 3px solid @cobalt-blue;			
		}
		
		
	}*/
}
.sub-menu > nav {
  margin-left: -30px;
  position: relative;
}
.sub-menu > nav > h4 {
  padding-left: 30px;
}
.sub-menu > nav .reset-container {
  margin-left: 30px;
}
.sub-menu > nav > div [aria-expanded='true']:before {
  width: 3px;
  height: 100%;
  background-color: white;
  content: " ";
  display: inline-block;
  position: absolute;
}
.sub-menu > nav li {
  position: relative;
  /* Try to identify Windows Edge*/
  /* Try to identify IE 10+
			input https://stackoverflow.com/questions/18907131/detecting-ie11-using-css-capability-feature-detection
			*/
}
.sub-menu > nav li:before {
  width: 3px;
  height: 100%;
  background-color: transparent;
  content: " ";
  display: inline-block;
  position: absolute;
}
.sub-menu > nav li.active:before {
  background-color: #0a3859;
}
.sub-menu > nav li > a {
  margin-left: 30px;
}
@supports (-ms-ime-align:auto) {
  .sub-menu > nav li {
    /* EXPERIMENTAL : apply a correction to the height by cutting the margin */
  }
  .sub-menu > nav li > a {
    margin-top: -21px;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .sub-menu > nav li {
    /* EXPERIMENTAL : apply a correction to the height by cutting the margin */
  }
  .sub-menu > nav li > a {
    margin-top: -21px;
  }
}
.sub-menu > nav li li {
  /* Try to identify Windows Edge*/
  /* Try to identify IE 10+
				input https://stackoverflow.com/questions/18907131/detecting-ie11-using-css-capability-feature-detection
				*/
}
.sub-menu > nav li li:before {
  width: 3px;
  height: 100%;
  background-color: transparent;
  content: " ";
  display: inline-block;
  position: absolute;
}
.sub-menu > nav li li.active:before {
  background-color: #0a3859;
}
.sub-menu > nav li li > a {
  padding-left: 15px;
}
@supports (-ms-ime-align:auto) {
  .sub-menu > nav li li {
    /* EXPERIMENTAL : apply a correction to the height setting margin to 0 */
  }
  .sub-menu > nav li li > a {
    margin-top: 0;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .sub-menu > nav li li {
    /* EXPERIMENTAL : apply a correction to the height setting margin to 0 */
  }
  .sub-menu > nav li li > a {
    margin-top: 0;
  }
}
.sub-menu > nav li li li:before {
  width: 3px;
  height: 100%;
  background-color: transparent;
  content: " ";
  display: inline-block;
  position: absolute;
}
.sub-menu > nav li li li.active:before {
  background-color: #0a3859;
}
.sub-menu > nav li li li > a {
  padding-left: 30px;
}
.sub-menu .sub-menu--content {
  max-height: none !important;
  visibility: visible !important;
}
@media (min-width:992px) {
  .sub-menu .sub-menu--content {
    height: auto !important;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .sub-menu .sub-menu--content {
    padding-bottom: 1px;
  }
}
.sub-menu .sub-menu--content > ul {
  padding-left: 0;
}
.sub-menu .level > ul {
  list-style: none;
  padding-left: 0;
}
.sub-menu li:before {
  content: none;
}
.sub-menu li a {
  display: block;
  padding: 0.5em 1.8em 0.5em 0;
  border-top: 1px solid #CBCBCB;
}
.sub-menu .deeper {
  position: relative;
}
.sub-menu .deeper i {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.5em;
  line-height: 21px;
}
.sub-menu .deeper i:before {
  font-size: 0.8em;
  transition: transform 300ms;
}
.sub-menu .deeper i[data-expander-state^='open']:before {
  transform: scaleY(-1);
}
.search {
  float: right;
  margin-left: 7px;
  font-size: 15px;
}
.search--toggler {
  background: #E8E8E8;
  transition: background 300ms;
}
.search--toggler:hover {
  background: #dbdbdb;
}
.search--toggler[data-accordion-state^='open'] i:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: "fonticons";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  vertical-align: top;
  line-height: inherit;
  content: "\E005";
}
.search--input {
  position: absolute;
  top: 31px;
  width: 100%;
  left: 0;
  background: #E8E8E8;
}
.search--input > .container {
  position: relative;
  padding-top: 1em;
  padding-bottom: 1em;
}
.search--input > .container:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: "fonticons";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  vertical-align: top;
  line-height: inherit;
  content: "\E014";
}
.search--input > .container:before {
  position: absolute;
}
.search--input span.sd > label.btn-default {
  opacity: 0.5;
}
.search--input input[type="text"] {
  padding: 0 0 0.5em 1.5em;
  background: transparent;
  border: none;
  border-bottom: 1px solid #343434;
}
.search--results {
  padding: 2em 0 1em;
  list-style: none;
  padding-left: 0;
}
.search--results li {
  position: relative;
}
.search--results li:before {
  content: '';
  display: block;
  height: 1px;
  width: 20%;
  margin: 1em 0;
  background: #343434;
}
.search--results li:first-child:before {
  content: none;
}
.search--results .blank-slate {
  padding: 30px 0;
  color: #CBCBCB;
  font-size: 22px;
  text-align: center;
}
.search--results li + .blank-slate {
  display: none;
}
.search--results b {
  color: #0085C6;
}
.search--result-link {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.search--more-results {
  color: #999999;
  transition: color 250ms;
}
.search--more-results:hover {
  color: #0085C6;
}
.search--more-results:hover i.gfx {
  animation: bouncedown 1.5s infinite;
}
@keyframes bouncedown {
  0%,
  10%,
  50%,
  70%,
  100% {
    transform: translateY(0);
  }
  45% {
    transform: translateY(6px);
  }
  60% {
    transform: translateY(2px);
  }
}
.service-menu {
  position: relative;
  margin-top: 0px;
  background: white;
}
.service-menu > .hidden-mobile {
  padding: 1em 0;
}
.service-menu ul {
  float: right;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}
.service-menu ul:before,
.service-menu ul:after {
  content: " ";
  display: table;
}
.service-menu ul:after {
  clear: both;
}
.service-menu ul > li {
  float: left;
}
.service-menu li {
  border-left: 1px solid #343434;
}
.service-menu li:first-child {
  border-left: none;
}
.service-menu li:last-child a {
  padding-right: 0;
}
.service-menu a {
  padding: 0 0.5em;
  text-transform: uppercase;
  font-size: 13px;
}
.service-menu .logo {
  margin: 20px 0;
}
@media (min-width:992px) {
  .service-menu .logo {
    margin-bottom: 1px;
  }
}
.service-menu .logo:before,
.service-menu .logo:after {
  content: " ";
  display: table;
}
.service-menu .logo:after {
  clear: both;
}
.service-menu .logo a {
  display: block;
  padding: 0;
  float: left;
}
.service-menu .logo img {
  width: 240px;
}
.service-menu .service-menu--relation {
  position: relative;
}
.service-menu .service-menu--department {
  position: absolute;
  right: 1em;
  bottom: -0.25em;
  text-align: right;
  margin-top: 20px;
  width: 70%;
}
.service-menu .service-menu--department a {
  color: #343434;
  text-transform: none;
  font-size: 15px;
}
#header {
  background: white;
}
.push-menu > .level {
  margin-left: -15px;
}
.push-menu > .level a {
  text-decoration: none;
}
.push-menu > .level > ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}
.push-menu > .level > ul:before,
.push-menu > .level > ul:after {
  content: " ";
  display: table;
}
.push-menu > .level > ul:after {
  clear: both;
}
.push-menu > .level > ul > li {
  float: left;
}
.push-menu > .level > ul > li > a {
  display: block;
  font-size: 17px;
  padding: 0.5em 1em;
  position: relative;
}
.push-menu > .level > ul > li > a:before,
.push-menu > .level > ul > li > a:after {
  content: '';
  position: absolute;
  bottom: 0;
  top: auto;
  width: 0;
  height: 2px;
  transition: width 300ms;
  background: #0C2447;
}
.anthracite .push-menu > .level > ul > li > a:before,
.anthracite .push-menu > .level > ul > li > a:after {
  background: #343434;
}
.marine .push-menu > .level > ul > li > a:before,
.marine .push-menu > .level > ul > li > a:after {
  background: #0C2447;
}
.orange .push-menu > .level > ul > li > a:before,
.orange .push-menu > .level > ul > li > a:after {
  background: #FCC200;
}
.green .push-menu > .level > ul > li > a:before,
.green .push-menu > .level > ul > li > a:after {
  background: #008C50;
}
.red .push-menu > .level > ul > li > a:before,
.red .push-menu > .level > ul > li > a:after {
  background: #C7063E;
}
.purple .push-menu > .level > ul > li > a:before,
.purple .push-menu > .level > ul > li > a:after {
  background: #B71570;
}
.sky .push-menu > .level > ul > li > a:before,
.sky .push-menu > .level > ul > li > a:after {
  background: #0085C6;
}
.ami .push-menu > .level > ul > li > a:before,
.ami .push-menu > .level > ul > li > a:after {
  background: #fab500;
}
.agef .push-menu > .level > ul > li > a:before,
.agef .push-menu > .level > ul > li > a:after {
  background: #702283;
}
.baurecht .push-menu > .level > ul > li > a:before,
.baurecht .push-menu > .level > ul > li > a:after {
  background: #fddb04;
}
.aref .push-menu > .level > ul > li > a:before,
.aref .push-menu > .level > ul > li > a:after {
  background: #d31920;
}
.fmentoring .push-menu > .level > ul > li > a:before,
.fmentoring .push-menu > .level > ul > li > a:after {
  background: #B1CB31;
}
.regard .push-menu > .level > ul > li > a:before,
.regard .push-menu > .level > ul > li > a:after {
  background: #DC861C;
}
.push-menu > .level > ul > li > a:before {
  left: 50%;
}
.push-menu > .level > ul > li > a:after {
  right: 50%;
}
.push-menu > .level > ul > li > a:hover:before,
.push-menu > .level > ul > li > a:hover:after {
  width: 50%;
}
.push-menu > .level > ul > li.hover > a:before,
.push-menu > .level > ul > li.active > a:before,
.push-menu > .level > ul > li.hover > a:after,
.push-menu > .level > ul > li.active > a:after {
  width: 50%;
}
.push-menu > .level > ul > li:first-child {
  padding-left: 15px;
}
.push-menu > .level > ul > li:first-child > a {
  padding-left: 0;
}
.push-menu .level ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}
.push-menu .level .level li {
  transition: background 300ms;
  background: #0C2447;
  color: white;
}
.anthracite .push-menu .level .level li {
  background: #343434;
}
.marine .push-menu .level .level li {
  background: #0C2447;
}
.orange .push-menu .level .level li {
  background: #FCC200;
}
.green .push-menu .level .level li {
  background: #008C50;
}
.red .push-menu .level .level li {
  background: #C7063E;
}
.purple .push-menu .level .level li {
  background: #B71570;
}
.sky .push-menu .level .level li {
  background: #0085C6;
}
.ami .push-menu .level .level li {
  background: #fab500;
}
.agef .push-menu .level .level li {
  background: #702283;
}
.baurecht .push-menu .level .level li {
  background: #fddb04;
}
.aref .push-menu .level .level li {
  background: #d31920;
}
.fmentoring .push-menu .level .level li {
  background: #B1CB31;
}
.regard .push-menu .level .level li {
  background: #DC861C;
}
.anthracite .push-menu .level .level li {
  color: white;
}
.marine .push-menu .level .level li {
  color: white;
}
.orange .push-menu .level .level li {
  color: #343434;
}
.green .push-menu .level .level li {
  color: white;
}
.red .push-menu .level .level li {
  color: white;
}
.purple .push-menu .level .level li {
  color: white;
}
.sky .push-menu .level .level li {
  color: white;
}
.ami .push-menu .level .level li {
  color: white;
}
.agef .push-menu .level .level li {
  color: white;
}
.baurecht .push-menu .level .level li {
  color: #343434;
}
.aref .push-menu .level .level li {
  color: white;
}
.fmentoring .push-menu .level .level li {
  color: white;
}
.regard .push-menu .level .level li {
  color: white;
}
.push-menu .level .level li:hover {
  background: #081931;
}
.anthracite .push-menu .level .level li:hover {
  background: #272727;
}
.marine .push-menu .level .level li:hover {
  background: #081931;
}
.orange .push-menu .level .level li:hover {
  background: #e3ae00;
}
.green .push-menu .level .level li:hover {
  background: #007341;
}
.red .push-menu .level .level li:hover {
  background: #ae0536;
}
.purple .push-menu .level .level li:hover {
  background: #a01262;
}
.sky .push-menu .level .level li:hover {
  background: #0074ad;
}
.ami .push-menu .level .level li:hover {
  background: #e1a300;
}
.agef .push-menu .level .level li:hover {
  background: #5f1d6f;
}
.baurecht .push-menu .level .level li:hover {
  background: #e6c702;
}
.aref .push-menu .level .level li:hover {
  background: #bc161d;
}
.fmentoring .push-menu .level .level li:hover {
  background: #9fb62c;
}
.regard .push-menu .level .level li:hover {
  background: #c57819;
}
.push-menu .level .level .deeper:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: "fonticons";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  vertical-align: top;
  line-height: inherit;
  content: "\E010";
}
.push-menu .level .level a,
.push-menu .level .level span {
  display: block;
  width: 250px;
  padding: 0.5em 3em 0.5em 1em;
  border-bottom: 1px solid white;
}
.marine .push-menu .level .level a,
.marine .push-menu .level .level span {
  border-bottom-color: #8692a3;
}
.red .push-menu .level .level a,
.red .push-menu .level .level span {
  border-bottom-color: #e3839f;
}
.green .push-menu .level .level a,
.green .push-menu .level .level span {
  border-bottom-color: #80c6a8;
}
.purple .push-menu .level .level a,
.purple .push-menu .level .level span {
  border-bottom-color: #db8ab8;
}
.anthracite .push-menu .level .level a,
.anthracite .push-menu .level .level span {
  border-bottom-color: #9a9a9a;
}
.orange .push-menu .level .level a,
.orange .push-menu .level .level span {
  border-bottom-color: #c99b00;
}
.sky .push-menu .level .level a,
.sky .push-menu .level .level span {
  border-bottom-color: #80c2e3;
}
.push-menu .level .level a.back,
.push-menu .level .level span.back {
  display: none;
}
.push-menu .level .level a.deeper:after,
.push-menu .level .level span.deeper:after,
.push-menu .level .level a .deeper,
.push-menu .level .level span .deeper {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em 1em;
  height: 100%;
  width: auto;
  background: transparent;
  border-left: solid 1px rgba(255, 255, 255, 0.3);
}
.orange .push-menu .level .level a.deeper:after,
.orange .push-menu .level .level span.deeper:after,
.orange .push-menu .level .level a .deeper,
.orange .push-menu .level .level span .deeper {
  border-left-color: rgba(0, 0, 0, 0.2);
}
.push-menu .level .level a .deeper:after,
.push-menu .level .level span .deeper:after {
  position: relative;
  padding: 0;
  border-left: none;
}
.filter.sub-menu [data-accordion-content] {
  height: auto !important;
}
.visible-lt-ie9 {
  display: block;
}
